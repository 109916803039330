@import '~@/themes/common.scss';

// ----------------------------- 当前站点公共颜色 start  -----------------------------
// 平台名称
$platform: 'sm';

// ----------------------------- 当前站点公共颜色 end -----------------------------

// ----------------------------- 当前站点主题颜色  -----------------------------
$themes: (
  gloden: (
    // 主题色
    primary-color: #ffe3aa,
    // 主题色渐变
    primary-color-gradient: #eecb9c,
    // 次主题色
    secondary-color: #eecb9c,
    // 主题色主要文字
    text-primary-color: #fff,
    // 主题色特殊文字
    theme-text-color-secondary: #653309,

    // 一级背景色
    bg-color-primary: #1b1b1b,
    // 二级背景色
    bg-color-secondary: #303030,

    // 背景分割线(边框色) 暂时保留--未使用
    bg-divider-color: #121418,

    // 背景主要文字色
    bg-text-color-primary: #fff,
    // 背景次要文字
    bg-text-color-secondary: #868686,
    // 背景三级文字色
    bg-text-color-tertiary: #a6a6a6,

    // 主题按钮背景色
    btn-bg-color-primary: #e7b677,
    // 主题按钮文字色
    btn-text-color-primary: #2c261a,

    // 弹窗背景色
    bg-popup-color: #303030,
    text-popup-color: #fff,

    // 界面背景渐变
    bg-view-gradient: linear-gradient(transparent, 2%, transparent),
    // 模块背景渐变
    bg-card-gradient: linear-gradient(180deg, #5b4528 0%, #e7b677 100%),
    promotion-useList-text-color: #00be53,
    // 会员中心顶部渐变
    member-bg-gradient: linear-gradient(180deg, #e8b778 0%, #1b1b1b 97.49%),
    // 会员中心卡片背景
    member-card-bg: url('~@/themes/#{$platform}/img/my/gloden_card_bg.png'),
    // 支付弹窗按钮背景
    pay_btn_bg_item: #2c261a,
    // 支付按钮边框色
    pay_btn_border_item: #2c261a,
    // 支付渠道背景
    pay_type_bg_color: #2c261a,
    // 未选中按钮背景
    pay_type_btn_noactive_color: #2c261a,
    //个人中心输入框背景
    basemodel_input_bg: #1b1b1b,
    //个人中心输入框背景
    basemodel_input_text: #fff,
    // 公告图片
    system-top-img: url('~@/themes/#{$platform}/img/common/golden_top.png'),
    // 退出登录-修改头像-温馨提示图片
    kind-tips-img: url('~@/themes/#{$platform}/img/my/gloden_tc_top.png'),
    // 短视频添加图片
    video_add: url('~@/themes/#{$platform}/img/my/gloden_add.png'),
    // 免费标签
    free_tag: linear-gradient(to right, #e2be66, #f7eda5, #e2be66),
    // vip背景
    vip_tag: linear-gradient(to bottom, #ff68f9 1.68%, #5d3cdd 88.75%),
    // 置顶
    zhiding_tag: linear-gradient(to right, #e2be66, #f7eda5, #e2be66),
    // 精华
    jinghua_tag: linear-gradient(to bottom, #ff68f9 1.68%, #5d3cdd 88.75%),
    // 搜索tag底色
    search_tg_bg: #2c261a
  ),
  dark: (
    // 主题色
    primary-color: #00c39a,
    // 主题色渐变
    primary-color-gradient: #00c39a,
    // 次主题色
    secondary-color: #f4b63e,
    // 主题色主要文字
    text-primary-color: #fff,
    // 主题色特殊文字
    theme-text-color-secondary: #653309,

    // 一级背景色
    bg-color-primary: #121418,
    // 二级背景色
    bg-color-secondary: #1b1e24,

    // 背景分割线(边框色) 暂时保留--未使用
    bg-divider-color: #121418,

    // 背景主要文字色
    bg-text-color-primary: #fff,
    // 背景次要文字
    bg-text-color-secondary: #bebbba,
    // 背景三级文字色
    bg-text-color-tertiary: #c5c5c5,

    // 主题按钮背景色
    btn-bg-color-primary: #00c39a,
    // 主题按钮文字色
    btn-text-color-primary: #fff,

    // 弹窗背景色
    bg-popup-color: #fff,
    // 弹窗字体颜色
    text-popup-color: #121418,
    // 界面背景渐变
    bg-view-gradient: linear-gradient(transparent, 2%, transparent),
    // 模块背景渐变
    bg-card-gradient: #1c4139,
    promotion-useList-text-color: #00be53,
    // 会员中心顶部渐变
    member-bg-gradient: linear-gradient(180deg, #00c39a 0%, #121418),
    // 会员中心卡片背景
    member-card-bg: url('~@/themes/#{$platform}/img/my/dark_card_bg.png'),
    // 支付弹窗按钮背景
    pay_btn_bg_item: #fff,
    // 支付按钮边框色
    pay_btn_border_item: #d1d1d1,
    // 支付渠道背景
    pay_type_bg_color: #fff,
    // 未选中按钮背景
    pay_type_btn_noactive_color: #edf9f7,
    //个人中心输入框背景
    basemodel_input_bg: #f5f5f5,
    //个人中心输入框背景
    basemodel_input_text: #121418,
    // 公告图片
    system-top-img: url('~@/themes/#{$platform}/img/common/dark_top.png'),
    // 退出登录-修改头像-温馨提示图片
    kind-tips-img: url('~@/themes/#{$platform}/img/my/dark_tc_top.png'),
    // 短视频添加图片
    video_add: url('~@/themes/#{$platform}/img/my/dark_add.png'),
    // 免费标签
    free_tag: linear-gradient(to right, #e2be66, #f7eda5, #e2be66),
    // vip背景
    vip_tag: linear-gradient(to right, #ffc85c 1.68%, #ff4452 88.75%),
    // 置顶
    zhiding_tag: #ffd155,
    // 精华
    jinghua_tag: #00c39a,
    // 搜索tag底色
    search_tg_bg: #1c4139
  ),
  light: (
    // 主题色
    primary-color: #b32d4e,
    // 主题色渐变
    primary-color-gradient: #ff6d91,
    // 次主题色
    secondary-color: #f4b63e,
    // 主题色主要文字
    text-primary-color: #fff,
    // 主题色特殊文字
    theme-text-color-secondary: #653309,
    // 一级背景色
    bg-color-primary: #0e0608,
    // 二级背景色
    bg-color-secondary: #1c1c1c,
    // 背景分割线(边框色) 暂时保留--未使用
    bg-divider-color: #0e0608,
    // 背景主要文字色
    bg-text-color-primary: #fff,
    // 背景次要文字
    bg-text-color-secondary: #949597,
    // 背景三级文字色
    bg-text-color-tertiary: #c5c5c5,
    // 弹窗背景色
    bg-popup-color: #fff,
    // 界面背景渐变
    bg-view-gradient: linear-gradient(#311010, 2%, transparent),
    // 模块背景渐变
    bg-card-gradient: linear-gradient(180deg, #2d1e1e 0%, #1c0d0f 100%),
    promotion-useList-text-color: #00be53,
    // 公告图片
    system-top-img: url('~@/themes/#{$platform}/img/common/light_top.png'),
    // 退出登录-修改头像-温馨提示图片
    kind-tips-img: url('~@/themes/#{$platform}/img/my/light_tc_top.png')
  )
);

// ----------------------当中站点公共样式-不要随意定义，否则后续版本多难于维护 ----------------------
.commonLinearGradient::before {
  content: '';
  height: 181px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
  @include theme {
    background: t(bg-view-gradient);
  }
}
